import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from '../App';
import '../styles/About.css';

const About = () => {
  const { theme } = useContext(ThemeContext);

    /* Greetings with typed and delayed states */
  const greetings = ['Hello', 'Bonjour', 'Hola', 'Ciao', 'Namaste', 'Salut', 'Hallo', 'Konnichiwa', 'Hej', 'Aloha', 'Nǐ hǎo', 'Olá', 'Kamusta', 'Szia', 'Guten Tag', 'Ahoj', 'Hei', 'Annyeong', 'Sawubona', 'Jambo', 'Shikamoo', 'Ahlan', 'Sannu'];
  const [currentGreetingIndex, setCurrentGreetingIndex] = useState(0);
  const [currentGreeting, setCurrentGreeting] = useState('');

  const [isTyping, setIsTyping] = useState(true);
  const [isDelaying, setIsDelaying] = useState(false);

  const [typingSpeed, setTypingSpeed] = useState(175);
  const [delayDuration, setDelayDuration] = useState(1000);
  
  /* Type and untype greetings */
  useEffect(() => {
    let timeout;
    if (isTyping) {
      if (currentGreeting.length < greetings[currentGreetingIndex].length) {
        timeout = setTimeout(() => {
          setCurrentGreeting(prevGreeting => {
            const nextLetter = greetings[currentGreetingIndex][prevGreeting.length];
            return prevGreeting + nextLetter;
          });
        }, typingSpeed);
      } else {
        setIsTyping(false);
        setIsDelaying(true);
      }
    } else if (isDelaying) {
      timeout = setTimeout(() => {
        setIsDelaying(false);
        setCurrentGreeting('');
        setCurrentGreetingIndex((currentGreetingIndex + 1) % greetings.length);
        setIsTyping(true);
      }, delayDuration);
    } else {
      if (currentGreeting.length > 0) {
        timeout = setTimeout(() => {
          setCurrentGreeting(prevGreeting => prevGreeting.slice(0, -1));
        }, typingSpeed);
      }
    }

    return () => clearTimeout(timeout);
  }, [currentGreeting, currentGreetingIndex, delayDuration, greetings, isTyping, isDelaying, typingSpeed]);

  return (
    <div id={ theme }>
        <h2 className='alternate-h2'>{currentGreeting}! I'm Vidhi.</h2>
        <div className="about-container">
        <div className="profile-pic">
            <div className='profile-img'></div>
        </div>
        <div className="about-content">
            <h2>{currentGreeting}! I'm Vidhi.</h2>
            <p>I’m a Software Engineering student at the University of Waterloo, stepping into my 2B term. 
              I like to blend logic and creativity, using programming to bring my ideas to fruition. 
              Apart from software, I like learning new languages, dancing, and exploring the world.</p>
            
            <p>Here's a glimpse of what I’ve been up to lately:</p>
            
            <ul>
              <li><span style={{ marginRight: '1em' }}>🚀</span>Founded <strong>WatGPT</strong>, a design team at the University of Waterloo. We partnered up with the Dean of Engineering’s Office and I’m leading the development of an AI-driven advising system for the Faculty of Engineering :)</li>
              <li><span style={{ marginRight: '1em' }}>✈️</span>Following a win at OlympiHacks, I got invited to <strong>Amsterdam</strong> for Solana's annual Conference last October. It was really exciting to be a part of the tech scene there!</li>
              <li><span style={{ marginRight: '1em' }}>🌏</span>Challenged myself to learn <strong>Mandarin</strong>, my 7th language!</li>
              <li><span style={{ marginRight: '1em' }}>🚘</span>Went on a <strong>road</strong> trip from Toronto to Vancouver :)</li>
              <li><span style={{ marginRight: '1em' }}>💃</span>I've been reconnecting with Kathak dance. I dedicated 11 years perfecting it and I hope to revive my <strong>YouTube</strong> channel to share this art form with others in a fun way.</li>
            </ul>
        </div>
        </div>
    </div>
  );
};

export default About;

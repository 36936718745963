import React from 'react';
import Montage from './ProjectMontage';
import Carousel from './ProjectCarousel';
import '../styles/Projects.css';

const Projects = () => {
  return (
    <div className="projects">
      <div className='section-title'>
         <h2>Project Gallery.</h2>
      </div>
      <Montage />
      <Carousel />
    </div>
  );
};

export default Projects;

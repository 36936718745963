import React from 'react';

const ExperienceItem = ({ title, description, imgClass, links }) => (
  <div className='experience-content'>
    <div className={`experience-img ${imgClass}`}></div>
    <div className='experience-details'>
      <div className='experience-heading'>
        <h3>{title}</h3>
      </div>
      {description.map((text, index) => (
        <p key={index}>
          {text.split(' ').map((word, idx) => {
            const link = links?.find(link => word.includes(link.text));
            return link ? (
              <a key={idx} href={link.href} target="_blank" rel='noreferrer'>{link.text}</a>
            ) : (
              <React.Fragment key={idx}>{word} </React.Fragment>
            );
          })}
        </p>
      ))}
    </div>
  </div>
);

export default ExperienceItem;

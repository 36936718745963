import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import "../styles/ProjectCarousel.css";

export default function Carousel() {
  var settings = {
    className: "center",
    centerMode: true,
    dots: true,         
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true
  };
  return (
    <Slider {...settings}>
      <div className="slider-item flourish">
        <div className='project-content'>
          <h2>Flourish</h2>
          <p>A hydroponics setup with mobile integration to grow better quality fruits and veggies. Implemented an IoT-based quality control system to monitor growth of plants with temperature and pH sensors and send mobile alerts during inconsistencies.</p>
          <a href="https://vidhi-26.github.io/Flourish-AgriTech/" target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' icon={ faLink }/>Project Link</a>
        </div>
      </div>
      <div className="slider-item kata">
        <div className='project-content'>
          <h2>Kata</h2>
          <p>Teaching women self-defense with computer vision. Extracted self defense poses from YouTube and TikTok tutorials and gamified learning martial arts for girls.</p>
          <a href="https://devpost.com/software/kata-6cf1am" target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' icon={ faLink }/>Project Link</a>
        </div>
      </div>
      <div className="slider-item ttr">
        <div className='project-content'>
          <h2>Ticket to Ride</h2>
          <p>An online version of the classic board game - Ticket To Ride (TTR) - with a Canadian route map. TTR is a cross-country train adventure where players can collect various train cars that enable them to claim railway routes connecting cities all over Canada.</p>
          <a href="https://github.com/Vidhi-26/TTR-Game" target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' icon={ faLink }/>Project Link</a>
        </div>
      </div>
      <div className="slider-item unimatch">
        <div className='project-content'>
          <h2>UniMatch</h2>
          <p>A comprehensive app that assists high school students in finding the most suitable post-secondary program in Canada.</p>
          <a href="https://github.com/Vidhi-26/UniMatch" target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' icon={ faLink }/>Project Link</a>
        </div>
      </div>
      <div className="slider-item innovogue">
        <div className='project-content'>
          <h2>Innovogue</h2>
          <p>A web app that provides users with personalized outfit recommendations based on the occasion and clothing items in their wardrobe.</p>
          <a href="https://devpost.com/software/innovogue" target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' icon={ faLink }/>Project Link</a>
        </div>
      </div>
    </Slider>
  );
}
import React, { useContext } from 'react';
import { ThemeContext } from '../App';
import '../styles/Slider.css';

const Slider = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className="content" id={ theme }>
        <div className="content-container">
            <ul className="content-container-list">
                <li className="content-container-list-item">SOFTWARE DEVELOPER</li>
                <li className="content-container-list-item">LANGUAGE ENTHUSIAST</li>
                <li className="content-container-list-item">AI EXPLORER</li>
                <li className="content-container-list-item">DANCE FANATIC</li>
            </ul>
        </div>
    </div>
  );
};

export default Slider;

import React, { useState, createContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Landing from './components/Landing';
import About from './components/About';
import Milestones from './components/Milestones';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Footer from './components/Footer';
import './App.css';

export const ThemeContext = createContext(null);

function App() {
  const hour = new Date().getHours();
  const lineIndex = Math.floor(hour / 24 * 24);
  const [theme, setTheme] = useState(lineIndex < 6 || lineIndex > 17 ? 'dark' : 'light');

  const toggleTheme = () => {
    setTheme((curTheme) => (curTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{theme, toggleTheme}}>
      <Router>
        <div className="App" id={theme}>
            <Navbar />
            <div className="animatedwrapper">
              <div className="dot dot--one"></div>
              <div className="dot dot--two"></div>
              <div className="dot dot--three"></div>
              <div className="dot--four"></div>
            </div>
            <Routes>
              <Route path="/" element={<MainPage />} />
            </Routes>
          </div>
      </Router>
    </ThemeContext.Provider>
  );
};

const MainPage = () => {
  return (
    <div>
      <div id='landing'>  <Landing /></div>
      <div id="about">  <About /></div>
      {/* <div id="milestones">  <Milestones /></div> */}
      <div id="experience">  <Experience /></div>
      <div id="work"> <Projects /></div>
      <div id="contact">  <Footer /></div>
    </div>
  );
};

export default App;

import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from '../App';
import '../styles/Navbar.css';

const Navbar = () => {
    const [scrolled, setScrolled] = useState(false);
    const { theme } = useContext(ThemeContext);
    
    useEffect(() => {
      const handleScroll = () => {
        const isScrolled = window.scrollY > 0;
        if (isScrolled !== scrolled) {
          setScrolled(isScrolled);
        }
      };
  
      /* Detect scroll event */
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [scrolled]);
  
    return (
        <div className='navbar-container'>
            <nav className={`navbar ${scrolled ? 'scrolled' : ''}`} id={ theme }>
                <ul className="navbar-list">
                    <li className="navbar-item">
                        <a href="#landing" className="navbar-link">
                            <div className="navbar-square">/</div>
                        </a>
                    </li>
                    <li className="navbar-item">
                        <a href="#about" className="navbar-link">About</a>
                    </li>
                    <li className="navbar-item">
                        <a href="#experience" className="navbar-link">Work</a>
                    </li>
                    <li className="navbar-item">
                        <a href="#contact" className="navbar-link">Contact</a>
                    </li>
                </ul>
            </nav>
        </div>
    );
  };
  
  export default Navbar;
  
import React from 'react';
import '../styles/Experience.css';
import ExperienceItem from './ExperienceItem';

const experiences = [
  {
    title: 'WatGPT',
    description: [
      'As Founder of WatGPT Design Team at the University of Waterloo, I am leading development of an AI-driven advising system for the Faculty of Engineering at UWaterloo.',
      'I am currently researching open source LLMs and designing models to personalize academic guidance.'
    ],
    imgClass: 'watgpt',
  },
  {
    title: 'Shopify',
    description: ['Incoming SWE Intern for Fall \'24'],
    imgClass: 'shopify',
  },
  {
    title: 'Venuiti Solutions Inc',
    description: [
      'I worked as a Backend Engineering Intern on a client healthcare application used by US Department of Defense for triage and treatment decision support to clinicians at point of care.',
      'Feel free to read this article by the US Air Force.',
    ],
    imgClass: 'venuiti',
    links: [
      { text: 'application', href: 'https://t6hs.com' },
      { text: 'this', href: 'https://www.af.mil/News/Article-Display/Article/1802958/medical-airmen-work-to-improve-patient-care-efficiency-in-afghanistan/' }
    ],
  },
  {
    title: 'OpenText',
    description: [
      'At OpenText, I worked as a Software Engineering Intern on Core Share, a cloud-based file sharing web and mobile application with white-labeling compatibility, offered in 9 languages.',
      'Feel free to read more here or download the Core Share app.',
    ],
    imgClass: 'opentext',
    links: [
      { text: 'here', href: 'https://www.opentext.com/products/core-share-content-collaboration' },
      { text: 'download', href: 'https://play.google.com/store/apps/details?id=com.opentext.m.core&hl=en_CA&gl=US' }
    ],
  },
  {
    title: 'Waterloo Blockchain',
    description: [
      'As VP of Education and Web Developer, I helped host an 11-week Solidity Bootcamp and rebuilt our website. I also got invited to the Pragma Conference at ETHWaterloo and met Vitalik Buterin!'
    ],
    imgClass: 'wlu-blockchain',
  },
  {
    title: 'GTA Wholesale and Promotions',
    description: [
      'I worked as a Software Engineering Intern to optimize high-traffic payment workflows on an e-commerce platform.'
    ],
    imgClass: 'gta',
  }
];

const Experience = () => (
  <div className="experience">
    <div className='experience-title'>
      <h2>Experience.</h2>
    </div>
    {experiences.map((experience, index) => (
      <ExperienceItem key={index} {...experience} />
    ))}
  </div>
);

export default Experience;

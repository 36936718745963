import React, { useContext } from 'react';
import { ThemeContext } from '../App';
import Slider from './Slider';
import '../styles/Landing.css';

const LandingPage = () => {
  const { theme } = useContext(ThemeContext);

  const handleButtonClick = (link) => {
    window.open(link, "_blank", "noopener noreferrer");
  };
  
  return (
    <div className="landing-page">
      <h1>Vidhi Ruparel</h1>
      <Slider />
      <div className="social-buttons" id={ theme }>
        <div className='button-container' onClick={() => handleButtonClick("https://www.linkedin.com/in/vidhi-ruparel/")}>
          <div className="button">LINKEDIN</div>
        </div>
        <div className='button-container' onClick={() => handleButtonClick("https://github.com/Vidhi-26")}>
          <div className="button">GITHUB</div>
        </div>
        <div className='button-container' onClick={() => handleButtonClick("mailto:vruparel@uwaterloo.ca")}>
          <div className="button">EMAIL</div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

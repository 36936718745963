import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import '../styles/ProjectMontage.css';

const Montage = () => {
  return (
    <div className='projects-layout'>
      <div className='project solana-rewards half one'>
        <div className='project-content'>
          <h2>Solana Rewards</h2>
          <p>A web app made entirely on the Solana blockchain. Users can fill out 2-5 minute surveys and earn free tokens through airdrops while advertisers gain targeted market insights.</p>
          <a href="https://devpost.com/software/solana-rewards" target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' icon={ faLink }/>Project Link</a>
        </div>
      </div>
      <div className='project lacs half two'>
        <div className='project-content'>
          <h2>LACS Compiler</h2>
          <p>A compiler created using Scala to translate a new custom programming language called LACS to MIPS Assembly.</p>
          {/* <a href="https://devpost.com/software/solana-rewards" target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' icon={ faLink }/>Project Link</a> */}
        </div>
      </div>
      <div className='project blockaoke full'>
        <div className='project-content'>
          <h2>Blockaoke: blockchain + karaoke</h2>
          <p>Let AI decide who is the best at singing in a karaoke battle with your friends in an immersive web3 experience. We use Siamese Neural Networks to decide which cover song most closely resembles the original.</p>
          <a href="https://ethglobal.com/showcase/blockaoke-unsoh" target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' icon={ faLink }/>Project Link</a>
        </div>
      </div>
      <div className='project symptomed full'>
        <div className='project-content'>
          <h2>SymptoMed</h2>
          <p>An application for early detection of diseases from symptoms.</p>
          <a href="https://github.com/Vidhi-26/SymptoMed" target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' icon={ faLink }/>Project Link</a>
        </div>
      </div>
      <div className='project pharmahacks full'>
        <div className='project-content'>
          <h2>Medex</h2>
          <p>Making the drug discovery process faster with AI. Created a stacked model that predicts the binding affinity of a drug to a protein target.</p>
          <a href="https://devpost.com/software/drug-screening-using-ml-models" target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' icon={ faLink }/>Project Link</a>
        </div>
      </div>
      <div className='project guido half one'>
        <div className='project-content'>
          <h2>WALL-E</h2>
          <p>An autonomous vacuum cleaner that uses Arduino, ultrasonic sensors and a suction to collect medium-sized garbage while avoiding obstacles. Simplifying waste management and household chores creatively.</p>
          <a href="https://github.com/ShreyasPeddi/Guido_SE101" target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' icon={ faLink }/>Project Link</a>
        </div>
      </div>
      <div className='project eq half two'>
        <div className='project-content'>
          <h2>EQ</h2>
          <p>EQ tells you whether the person you are texting is “actually” angry, sad, happy or feeling any other 27 emotions. It also recommends a reply you can text back based on the mood of the person you are chatting with.</p>
          <a href="https://github.com/Vidhi-26/EQ" target='_blank' rel='noreferrer'><FontAwesomeIcon className='icon' icon={ faLink }/>Project Link</a>
        </div>
      </div>
    </div>
  );
};

export default Montage;

import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify } from '@fortawesome/free-brands-svg-icons';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import '../styles/Footer.css';

const Footer = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  /* Lines */
  const hour = new Date().getHours();
  const lineIndex = Math.floor(hour / 24 * 24);
  const [activeLine, setActiveLine] = useState(lineIndex);
  const [prevSelected, setPrevSelected] = useState(lineIndex);
  
  /* Initial setup */
  useEffect(() => {
    document.getElementById(`icon-box-${prevSelected}`).style.visibility = 'visible';
    
    const adjacentLines = document.querySelectorAll('.line');
  
    adjacentLines[activeLine].classList.add('active');
    adjacentLines[activeLine - 1]?.classList.add('active-adjacent');
    adjacentLines[activeLine + 1]?.classList.add('active-adjacent');
  }, []);

  /* Helper functions */
  const isMiddle = (index) => {
    return index >= 6 && index <= 17;
  };  
  
  /* Line click and hover handlers */
  const handleLineClick = (index) => {
    document.getElementById(`icon-box-${prevSelected}`).style.visibility = 'hidden';

    if (!isMiddle(prevSelected) && isMiddle(index)) {
      toggleTheme('light');   // Toggle to light theme
    } else if (isMiddle(prevSelected) && !isMiddle(index)) {
      toggleTheme('dark');    // Toggle to dark theme
    }

    document.getElementById(`icon-box-${index}`).style.visibility = 'visible';
    setPrevSelected(index);
  };
  
  const handleLineHover = (index) => {
    const adjacentLines = document.querySelectorAll('.line');
    
    adjacentLines[activeLine].classList.remove('active');
    adjacentLines[activeLine - 1]?.classList.remove('active-adjacent');
    adjacentLines[activeLine + 1]?.classList.remove('active-adjacent');
    
    adjacentLines[index].classList.add('active');
    adjacentLines[index - 1]?.classList.add('active-adjacent');
    adjacentLines[index + 1]?.classList.add('active-adjacent');
    
    setActiveLine(index);
  };
  
  return (
    <footer className={`footer ${theme}`} id={ theme }>
      <div className="footer-content">
        <div className='left-content'>
          <div className="spotify-box">
            <FontAwesomeIcon className='spotify-logo' icon={faSpotify} />
            <div className="repeat-content">
              <p className='repeat-text'>On Repeat</p>
              <p className='song-text'>The Next Big Idea with Rufus Griscom</p>
            </div>
          </div>
          <div className="name-text">
            <p>Developed by Vidhi Ruparel. All rights reserved.</p>
          </div>
        </div>
        <div className="right-content">
          <p>Elsewhere</p>
          <ul>
            <li><a href="https://www.linkedin.com/in/vidhi-ruparel/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
            <li><a href="https://github.com/Vidhi-26" target="_blank" rel="noopener noreferrer">GitHub</a></li>
            <li><a href="mailto:vruparel@uwaterloo.ca">Email</a></li>
          </ul>
        </div>
      </div>

      <div className='icons-container'>
        {[...Array(24)].map((_, index) => (
          <div key={index} id={`icon-box-${index}`} className='icon-box'>
            <FontAwesomeIcon className='icon' icon={index >= 6 && index <= 17 ? faSun : faMoon} size='1x' id= {`icon-${index}`}/>
          </div>
        ))}
      </div>
      
      <div className="lines-container">
        {[...Array(24)].map((_, index) => (
          <div
            key={index}
            className='line'
            onMouseEnter={() => handleLineHover(index)}
            onClick={() => handleLineClick(index)}
          >
            <div className={`line-inner ${index >= 6 && index <= 17 ? 'middle-line-inner' : ''}`}></div>
          </div>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
